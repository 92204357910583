<template>
  <section>
    <b-row>
      <b-col :md="6">
        <b-card>
          <validation-observer ref="simpleRules">
            <b-form class="container">
              <b-row>
                <b-col md="12">
                  <label>Termék *</label>

                  <b-form-group>
                    <v-select
                      v-model="selectedProduct"
                      label="name"
                      :options="allProducts()"
                      @input="selectProductId($event)"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Mennyiség *</label>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      mode="passive"
                    >
                      <b-input-group :append="unit">
                        <b-form-input
                          v-model="formData.qty"
                          type="number"
                          :state="errors.length > 0 ? false : null"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <label>Forrás raktár *</label>
                  <b-form-group>
                    <v-select
                      v-model="selectedSourceWarehouse"
                      label="name"
                      :options="allWarehouses()"
                      @input="selectSourceWarehouseId($event)"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <label>Cél raktár *</label>
                  <b-form-group>
                    <v-select
                      v-model="selectedTargetWarehouse"
                      label="name"
                      :options="allWarehouses()"
                      @input="selectTargetWarehouseId($event)"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-form-group>
                    <label>Dokumentum sorszáma (pl.: számlasorszám)</label>
                    <b-form-input
                      v-model="formData.document_number"
                      type="number"
                    />
                  </b-form-group>
                </b-col>
                <!-- submit button -->
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                    Mentés
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col :md="6">
        <b-card>
          <b-overlay :show="loading">
            <app-timeline>
              <app-timeline-item
                v-for="(transferLog, index) in allTransferLogs()"
                :key="index"
                :title="transferLog.product.name"
                :subtitle="`Mennyiség: ${transferLog.quantity}, Forrás raktár: ${transferLog.source_warehouse.name}, Cél raktár: ${transferLog.target_warehouse.name}, Felhasználó: ${transferLog.user.name}`"
                :time="transferLog.created_at.readable"
              />
            </app-timeline>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import vSelect from 'vue-select'
import {
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BForm,
  BCard,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCard,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    AppTimeline,
    AppTimelineItem,
  },
  data() {
    return {
      formData: {
        product_id: null,
        qty: null,
        source_warehouse_id: null,
        target_warehouse_id: null,
        document_number: null,
      },
      selectedProduct: null,
      selectedSourceWarehouse: null,
      selectedTargetWarehouse: null,
      unit: null,
      loading: false,
      required,
    }
  },
  methods: {
    ...mapActions([
      'createTransferLog',
      'fetchTransferLogs',
      'fetchProducts',
      'fetchWarehouses',
    ]),
    ...mapGetters([
      'allProducts',
      'allTransferLogs',
      'allWarehouses',
    ]),
    initProducts() {
      this.loading = true
      this.fetchProducts().then(res => {
        this.loading = false
      })
    },
    initWarehouses() {
      this.loading = true
      this.fetchWarehouses().then(res => {
        this.loading = false
      })
    },
    initTransferLogs() {
      this.loading = true
      this.fetchTransferLogs().then(res => {
        this.loading = false
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.createTransferLog(this.formData).then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sikeres mentés',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.formData.product_id = null
            this.formData.qty = null
            this.formData.source_warehouse_id = null
            this.formData.target_warehouse_id = null
            this.formData.document_number = null
            this.selectedProduct = null
            this.selectedSourceWarehouse = null
            this.selectedTargetWarehouse = null
            this.initTransferLogs()
          })
        }
      })
    },
    selectProductId(e) {
      this.formData.product_id = e.id
      this.unit = e.unit
    },
    selectSourceWarehouseId(e) {
      this.formData.source_warehouse_id = e.id
    },
    selectTargetWarehouseId(e) {
      this.formData.target_warehouse_id = e.id
    },
  },
  created() {
    this.initProducts()
    this.initWarehouses()
    this.initTransferLogs()
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
